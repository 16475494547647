


































































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
  }),
  computed: {
  },
  mounted() {
  },
  methods: {
    async phoneCall(phoneNumber: string) {
      const telLink = `tel:${phoneNumber}`;
      window.location.href = telLink;
    },
    async openLinkExternal(link: string) {
      window.open(link);
    },
    async openLink(link: string) {
      window.location.href = link;
    },
  },
});
